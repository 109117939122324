import Request from '../../../shared/request';
import authorizationStore from '../../../shared/authorizationStore';

const formatCategories = (categories) => {
  const categoryList = [];
  if (categories) {
    for (let i = 0; i < categories.length; i++) {
      if (categories[i].parentName === 'products_and_services' || categories[i].source === 7) {
        categoryList.push(categories[i].name);
      }
    }
  }
  return categoryList;
};

const formatRecommendations = (recommendations) => {
  const response = [];
  for (let i = 0; i < recommendations.length; i++) {
    const item = recommendations[i];
    response.push({
      id: item.id,
      title: item.title,
      description: item.description,
      logo: item.logo,
      link: item.link,
      categories: formatCategories(item.categories),
      isNew: item.isNewToShow,
      isInnovator: item.isInnovator,
      recommendationType: item.recommendationType
    });
  }
  return response;
};

const getRecommendationsFromMARS = (displayCount, eventEditionId, locale, interfaceLocale, objectId, baseApiUrl) => {
  const accessToken = authorizationStore.getIDToken();
  const query =
        `{
            exhibitorRecommendations(
                eventEditionId: "${eventEditionId}",
                locale: "${locale}",
                interfaceLocale: "${interfaceLocale}",
                organisationId: "${objectId}",
                source: 0,
                maxResult: ${displayCount},
                accessToken: "${accessToken}")
            {
                id,
                title,
                description,
                logo,
                link,
                isInnovator,
                isNewToShow,
                recommendationType,
                categories{
                    name,
                    source,
                    parentName
                }
            }
        }`;
  return Request.graphqlRequest(baseApiUrl, query)
        .then((response) =>
          response.body.exhibitorRecommendations)
        .then(formatRecommendations)
        .catch(() =>
          null);
};

export default { getRecommendationsFromMARS };
