/* global window:true */

import fetch from './fetch';
import authorizationStore from './authorizationStore';

const httpVerb = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE'
};

const status = {
  OK: 200,
  BAD_REQUEST: 400
};

const acceptType = 'application/json';
const contentType = 'application/json';

const mergeHeaders = async (customHeaders) => {
  const token = await authorizationStore.getAuthToken();
  const clientId = authorizationStore.getClientId();
  const bearer = token ? { Authorization: `Bearer ${token}` } : {};
  const xClientId = clientId ? { 'X-ClientId': clientId } : {};
  return {
    Accept: acceptType, ...bearer, ...xClientId, ...customHeaders
  };
};

const shouldReject = (response) =>
  response.status >= status.BAD_REQUEST;

const shouldRejectGraphql = (response) =>
  response.status >= status.BAD_REQUEST || (response.errors && response.errors.length > 0);

const extractStatusAndBodyData = (resp) =>
  resp
  .json()
  .then((body) =>
    ({ status: resp.status, body: body.data, errors: body.errors }));

const resultForStatusGraphql = (result) => {
  if (shouldRejectGraphql(result)) {
    return Promise.reject(result);
  }
  return result;
};

const graphqlRequest = async (url, query, variables) => {
  const version = url.includes('apigee.net') || url.includes('api.rxweb-pre.com') || url.includes('api.reedexpo.com') ? 'v2/' : '';
  const customHeaders = { 'Content-Type': contentType };
  const body = JSON.stringify({
    query,
    variables
  });
  const headers = await mergeHeaders(customHeaders);
  return fetch(`${url}${version}graphql`, { method: httpVerb.POST, headers, body })
    .then(extractStatusAndBodyData)
    .then(resultForStatusGraphql);
};

export default {
  graphqlRequest,
  shouldReject
};
